import React from 'react'
import MediaQuery from 'react-responsive';
import { Link } from 'gatsby'

const Header = ({ siteTitle }) => (
  <div>
    <MediaQuery query="(min-device-width: 768px)">
  <div
    style={{
      marginTop:425,
      background: '#f9f9f9',
      textAlign: 'center',
    }}
  >

    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        paddingTop: '32px',
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            fontSize: '40px',
            fontWeight: '500',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '1.35',
            letterSpacing: 'normal',
            textAlign: 'center',
            color: '#34393c',
            textDecoration: 'none',  
            display: 'none', 
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </div>
  </MediaQuery>
  <MediaQuery query="(max-device-width: 766px)">
  <div
    style={{
      marginTop:200,
      background: '#f9f9f9',
      textAlign: 'center',
    }}
  >

    <div
      style={{
        margin: '0 auto',
        maxWidth: '300px',
        paddingTop: '32px',
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            fontSize: '30px',
            fontWeight: '500',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '1.35',
            letterSpacing: 'normal',
            textAlign: 'center',
            color: '#34393c',
            textDecoration: 'none',   
            display: 'none',
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </div>
  </MediaQuery>
  </div>  
)


export default Header
